import { useLayoutEffect } from 'react'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import { Helmet } from 'react-helmet'
import { useFetch } from '@shared/hooks'
import { Wrapper } from '@pages/App/App.styled'

const firebaseConfig = {
  apiKey: 'AIzaSyCRHbS3_49qI2xkEzTd4aTby7mfdciDvr0',
  authDomain: 'pwa-constructor.firebaseapp.com',
  projectId: 'pwa-constructor',
  storageBucket: 'pwa-constructor.appspot.com',
  messagingSenderId: '431248725189',
  appId: '1:431248725189:web:b2c8cd718cd7a38443ccac',
}

const SUB3 = process.env.SUB3

const app = initializeApp(firebaseConfig)
export const App = ({ isDisplay }: { isDisplay: boolean }) => {
  const { request } = useFetch()
  const session_id = localStorage.getItem('sessionId')
  const click_id = localStorage.getItem('clickId')
  const open_PWA = localStorage.getItem('openPWA')

  useLayoutEffect(() => {
    const messaging = getMessaging(app)

    Notification.requestPermission().then(async permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.')

        try {
          const currentToken = await getToken(messaging)

          if (currentToken) {
            console.log('Token:', currentToken)

            const url = 'https://api.pwa-app.pro/api/v1/firebaseSubscription/send'
            const body = {
              device_token: currentToken,
            }

            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            })

            if (!response.ok) {
              throw new Error('Network response was not ok')
            }

            const data = await response.json()
            console.log('Response from server:', data)
          } else {
            console.log('No registration token available. Request permission to generate one.')
          }
        } catch (error) {
          console.error('Error sending subscription:', error)
        }
      } else {
        console.log('User denied notification permission.')
      }
    })

    // // Устанавливаем обработчик входящих уведомлений
    onMessage(messaging, payload => {
      console.log('Received message:', payload)

      const { notification } = payload
      if (notification) {
        const { title, body, icon } = notification
        // Пример использования нативного уведомления браузера
        new Notification(title ?? '', {
          body,
          icon,
          data: { url: 'https://new.pwa-app.pro/' }, // URL вашего PWA
        }).onclick = function (event) {
          event.preventDefault()
          window.open('https://new.pwa-app.pro/', '_blank')
        }
      }
    })
  }, [])

  useLayoutEffect(() => {
    if (!open_PWA && session_id) {
      const requestBody = {
        session_id,
      }
      const url = 'https://api.analytical-space.com/api/application-pwa/open-pwa/'

      request(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      }).then(() => {
        localStorage.setItem('openPWA', 'true')
      })
    }
  }, [])

  return (
    <Wrapper>
      <Helmet>
        <title>TopX</title>
      </Helmet>
      <iframe
        style={{ display: !isDisplay ? 'none' : undefined }}
        src={`https://topx.one/signup3/?${
          SUB3 ? `sub3=${SUB3}` : ''
        }&session_id=${session_id}&partnerId=${click_id}&partner_id=${click_id}`}
      />
    </Wrapper>
  )
}
